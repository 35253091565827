import { Component, OnInit } from '@angular/core';

import { AlertController, NavController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UtilService } from './services/util.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit{
  usuarioLogado = false;
  paginaAdminCollection = [];
  private promptInstallEvent;
  private toast: HTMLIonToastElement;
  selectedIndex:any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private utilService: UtilService,
    private navCtrl: NavController,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
  ) {
    this.initializeApp();

    //Carrega o menu em caso de refresh

    this.paginaAdminCollection = JSON.parse(localStorage.getItem('menuCollection'));

    this.utilService.getEvent().subscribe((resp: any) => {
      //debugger;
      //console.log(resp);

      if (resp.key == "usuarioLogado") {
        if (resp.value == true) {
          this.usuarioLogado = true;
          //carrega o menu depois que loga
          this.paginaAdminCollection = JSON.parse(localStorage.getItem('menuCollection'));
        }
      }
    });

    this.usuarioLogado = this.utilService.isUsuarioLogado();



  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.populaMenuAdmin();
  }
 
  async ngOnInit() {

    console.log(`Runing app ${this.isPWAInstalled ? 'standalone' : 'in browser'}`);

    this.swUpdate.available.subscribe(async event => {

      console.log('current version is', event.current);
      console.log('available version is', event.available);

      if (event.current !== event.available) {
        const alert = await this.alertCtrl.create({
          header: 'Oba, Temos Novidades!',
          subHeader: 'Há uma nova versão disponível da aplicação.',
          message: 'Deseja atualizar agora?',
          buttons: [
            {
              text: 'Instalar',
              handler: () => { this.swUpdate.activateUpdate(); }
            },
            'Mais tarde'
          ]
        });
        alert.present();
      }
    });

    this.swUpdate.activated.subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });

    await this.platform.ready();
    
    if (!this.isMobile) {
      this.checkForUpdate();
      if (!this.isPWAInstalled) {
        this.listenForInstallEvent();
      }
    }

    console.log('swUpdate.isEnabled: ' + this.swUpdate.isEnabled);
    //this.swUpdate.checkForUpdate();
  }

  private listenForInstallEvent() {
    window.addEventListener('beforeinstallprompt', async (e) => {
      e.preventDefault();
      this.promptInstallEvent = e;
      
      setTimeout(() => {
        this.suggestInstall();
      }, 5000);
    });
  }
  private async suggestInstall() {
    this.toast = await this.toastCtrl.create({
      message: 'Você pode usar este aplicativo offline',
      buttons: [{
        text: 'Baixar',
        handler: () => { this.installPWA(); },
      }, {
        text: '',
        icon: 'close'
      }],
      duration: 0,
    });
    this.toast.present();
  }

  private installPWA() {
    this.toast.dismiss();
    // Show the prompt
    this.promptInstallEvent.prompt();
    // Wait for the user to respond to the prompt
    this.promptInstallEvent.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.promptInstallEvent = null;
      });
  }
  
 get isMobile() {
    return this.platform.is('mobile');
  }
  get isPWAInstalled(): boolean {
    return window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone;
  }

  async checkForUpdate() {
    console.log('Check for updates');
    try {
      await this.swUpdate.checkForUpdate();
    } catch (e) {
      console.debug('service worker not available');
    }
  }

  populaMenuAdmin() {
    this.paginaAdminCollection = [];
  }

  sair() {
    localStorage.clear();
    this.navCtrl.navigateRoot('login');
    this.utilService.publishEvent("usuarioLogado", false);
  }

}
