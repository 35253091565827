import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from './guards/authorize.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'replay',
    pathMatch: 'full'
  },
  {
    path: 'replay',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/replay/replay.module').then( m => m.ReplayPageModule)
  },
  {
    path: 'solicitacao-listar',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/solicitacao-listar/solicitacao-listar.module').then( m => m.SolicitacaoListarPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/acesso/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'lembrar-senha',
    loadChildren: () => import('./pages/acesso/lembrar-senha/lembrar-senha.module').then( m => m.LembrarSenhaPageModule)
  },
  {
    path: 'solicitacao-adicionar',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/solicitacao-adicionar/solicitacao-adicionar.module').then( m => m.SolicitacaoAdicionarPageModule)
  },
  {
    path: 'solicitacao-adicionar',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/solicitacao-adicionar/solicitacao-adicionar.module').then( m => m.SolicitacaoAdicionarPageModule)
  },
  {
    path: 'qsti/empresa-novo',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/qsti/empresa-novo/empresa-novo.module').then(m => m.EmpresaNovoPageModule)
  },
  {
    path: 'qsti/empresa-consultar',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/qsti/empresa-consultar/empresa-consultar.module').then(m => m.EmpresaConsultarPageModule)
  },
  {
    path: 'qsti/usuario-consultar',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/qsti/usuario-consultar/usuario-consultar.module').then( m => m.UsuarioConsultarPageModule)
  },
  {
    path: 'qsti/usuario-novo',
    loadChildren: () => import('./pages/qsti/usuario-novo/usuario-novo.module').then( m => m.UsuarioNovoPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
